import React from "react";
import ProjectShowcase from "../components/Projects/ProjectShowcase";

const Experience = () => {
    return (
        <div className="min-h-screen w-screen bg-gradient-to-r from-calgar-blue/30 to-night-dive/30 ">
            <div className="flex w-screen justify-center pt-[20px]">
                <div className="flex items-center">
                    <h1 className="text-6xl font-sans text-bauhaus-blue text-center pr-3">Experience and Projects</h1>
                </div>
            </div>
            <div className="flex w-screen justify-center pt-[20px]">
                <h3 className="text-2xl pb-10 font-sans text-bauhaus-blue">Here you can learn more about some of my favorite projects</h3>
            </div>
            <ProjectShowcase />
        </div>
    );
}

export default Experience;